import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { SEO } from '../components/SEO'

const Template = ({data}) => {
    const { markdownRemark } = data
    const title = markdownRemark.frontmatter.title
    const html = markdownRemark.html

    return (
        <>
        <SEO title={title} />
        <Header />
        <div className="container mx-auto mt-10">
            <div className="relative px-4 sm:px-4 lg:px-4">
                <div className="text-lg max-w-prose mx-auto">
                     <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{title}</span>
                    </h1>
                </div>
                    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto"
                        dangerouslySetInnerHTML={{__html: html}}
                    />
             </div>
        </div>
        <Footer />
        </>
    )
}

export const query = graphql`
    query($pathSlug: String!) {
        markdownRemark(frontmatter: { path: {eq: $pathSlug}}) {
            html
            frontmatter {
                title
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

export default Template